import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { NextPage } from 'next';
import Header from '../components/Header';
import { AuthSSR } from '../utils/AuthSSR';
import { supabase } from '../utils/supabase';
import { useAuth } from '../state/hooks/useAuth';
import { IUser } from './api/middlewares/withAuth';
import { setCookie } from 'nookies';

interface IHomeProps {
  user: IUser;
  cookies: { [key: string]: string };
}

const Home: NextPage<IHomeProps> = ({ user }) => {
  const router = useRouter();
  const { setAuthUserSuccess } = useAuth();

  useEffect(() => {
    const session = supabase.auth.session();

    if (user.data && !session) {
      supabase.auth.session = () => ({
        access_token: user.data?.token || '',
        token_type: 'bearer',
        user: user.data?.userSession?.user || null,
      });

      setCookie(null, 'token', user?.data?.token || '');
      setAuthUserSuccess({
        id: user.data?.id || 0,
        name: user.data?.name || '',
        fullname: user.data?.fullname || '',
        email: user.data?.email || '',
        token: user.data?.token || '',
      });
    } else if (session) {
      router.push('/account');
    } else {
      window.location.href = `${process.env.NEXT_PUBLIC_LANDING_URL}/login`;
    }

    supabase.auth.onAuthStateChange((_event, session) => {
      if (_event === 'SIGNED_IN') router.push('/account');
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <main></main>
      <footer></footer>
    </>
  );
};

export const getServerSideProps = AuthSSR('/account/dashboard');

export default Home;
